<template>
  <nav class="navbar navbar-light bg-body">
    <div class="container-fluid">
      <a class="" href="/">
        <img :src="require('@/assets/logo_mf.png')" alt="" width="36" height="36" class="d-inline-block align-text-top">
      </a>
      <ul class="nav justify-content-end">
        <li v-if="isLogin" class="nav-item">
          <a class="nav-link disabled">{{ username }}</a>
        </li>
        <li v-else class="nav-item">
          <a class="nav-link" href="#">登陆</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Header',
  data: function () {
    return {
      isLogin: true,
      username: 'chuang'
    }
  }
}
</script>

<style scoped>
</style>
