<template>
  <div class="container-fluid">
    <div class="container">
      <div class="row pt-5">
        <div class="col-md-3"></div>
        <div class="col-md-6">
          <Search :source-list="sourceList" :search="search"></Search>
        </div>
        <div class="col-md-3"></div>
      </div>
      <div class="mt-5 bg-body">
        <div v-for="(item,index) in list" :key="'list' + index" class="">
          <div>
            <CategoryItem :category-name="item.categoryName" :itemList="item.itemList"></CategoryItem>
          </div>
        </div>
      </div>
      <div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from '@/components/search/Search'
import CategoryItem from '@/components/category/CategoryItem'

export default {
  name: 'Home',
  components: {
    Search,
    CategoryItem
  },
  data () {
    return {
      sourceList: [{
        name: 'Bing',
        searchSrc: 'https://cn.bing.com/search?q='
      }, {
        name: '百度',
        searchSrc: 'https://www.baidu.com/s?wd='
      }],
      list: [{
        categoryName: '软件管家',
        itemList: [{
          name: '软件下载',
          url: '/soft',
          icon: require('@/assets/icon_software_my.png'),
          isWebSite: false
        }]
      },
      {
        categoryName: '生活必备',
        itemList: [{
          name: '百度翻译',
          url: 'https://fanyi.baidu.com/?aldtype=16047#zh/en',
          icon: require('@/assets/baidu_fanyi.jpg'),
          isWebSite: true
        }, {
          name: '深圳交警',
          url: 'http://szjj.sz.gov.cn/',
          icon: require('@/assets/icon_life_szjj.png'),
          isWebSite: true
        }, {
          name: '深圳限行申请',
          url: 'http://app.szjj.sz.gov.cn:8081/WebJOA/sbtxz.jsp?sxbm=90002900300754268913440300',
          icon: require('@/assets/icon_life_szjj.png'),
          isWebSite: true
        }]
      },
      {
        categoryName: '开发在线',
        itemList: [{
          name: 'JSON转换',
          url: 'https://c.runoob.com/front-end/53/',
          icon: require('@/assets/icon_tool_json.png'),
          isWebSite: true
        }, {
          name: '菜鸟工具',
          url: 'https://c.runoob.com/',
          icon: require('@/assets/icon_tool_cainiao.png'),
          isWebSite: true
        }]
      }]
    }
  },
  methods: {
    search (index, keyword) {
      window.open(this.sourceList[index].searchSrc + keyword)
    }
  }
}
</script>

<style scoped>
</style>
