<template>
  <div class="min-vh-100 position-relative" style="padding-bottom: 6rem;">
<!--    <Header1></Header1>-->
    <Header/>
    <router-view/>
    <div class="position-absolute bottom-0 w-100">
      <Footer/>
    </div>
  </div>
</template>

<script>import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import Footer from '@/components/layout/Footer'
import Header from '@/components/layout/Header'

export default {
  name: 'Layout',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>

</style>
