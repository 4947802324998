<template>
  <div class="container">
    <div class="row py-3">
      <div class="col-1 align-self-center">{{ categoryName }}</div>
      <div class="col-11">
        <div v-for="(item,index) in itemList" :key="'itemList' + index" class="float-start text-center">
          <a v-if="item.isWebSite" :href="item.url" target="_blank" class="btn">
            <div class="card-group">
              <img class="h-100 mx-1" :src="item.icon" alt="">
              <div class="align-self-center">{{ item.name }}</div>
            </div>
          </a>
          <router-link v-else :to="item.url" class="btn">
            <div class="card-group">
              <img class="h-100 mx-1" :src="item.icon" alt="">
              <div class="align-self-center">{{ item.name }}</div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CategoryItem',
  data: function () {
    return {}
  },
  props: {
    itemList: {
      type: Array
    },
    categoryName: {
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>
.container .row .input-group {
  height: 30px;
}
</style>
